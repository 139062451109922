import React, { Component, PropTypes, useState, useEffect } from "react"
import { useForm } from 'react-hook-form'
import axios from 'axios'
import StarRating from './StarRating'
import { useHistory } from "react-router"


//export default class DealDetails extends Component {    
const DealDetails = (props) => {    

    const basePath = localStorage.getItem("basePath");
    const [state, setState] = useState({});
    const [deal, setDeal] = useState(null);
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();

    async function fetchInterestedData() {        
        axios.get(`${basePath}?rest_route=/wp/v2/interesteddeals`)
        .then(res => {            
            if(res.data.length > 0){                
                const dealInterestedData = res.data;
                const isInterestedIndicated = dealInterestedData.filter(obj => obj.acf.deal.ID == props.match.params.id && obj.acf.interested_by.ID == localStorage.getItem('authUID'));                
                if(isInterestedIndicated.length > 0){                
                    setState({                    
                        isInterestedIndicated: true
                    });  
                }else{
                    setState({                    
                        isInterestedIndicated: false
                    });
                }              
            }   
        })
        .catch(err => console.log(err));
    }

    async function fetchDealData(id) {
        console.log(id);
        axios.get(`${basePath}?rest_route=/wp/v2/deals/`+id)
        .then(res => {
            setDeal({
                dealData: res.data,
                dealId: id,
                isLoaded: true
            });
            //const title = res.data.title.rendered+" ("+localStorage.getItem('authName')+")";
            fetchInterestedData();
        })
        .catch(err => console.log(err));
    }
    
    useEffect(() => {
        fetchDealData(props.match.params.id);        
    }, [props.match.params.id]);

    const backToMarketplace = () => {           
        props.history.push("/marketplace");
    }

    const onSubmitInterest = (dataOBJ) => {  
        const payloadData = JSON.stringify({  
            title: dataOBJ.dealtitle+' ('+ localStorage.getItem('authName') +')',
            status: 'publish',
            fields: {
                deal: props.match.params.id,
                interested_by: localStorage.getItem('authUID'),
                investment_amount: dataOBJ.interestedamount,
                rating: dataOBJ.starrating,
                questions: dataOBJ.interestedquestions
            }
        });            
      
        var config = {
            method: 'post',
            url: `${basePath}?rest_route=/wp/v2/interesteddeals`,
            headers: {
            'Content-Type': 'application/json',
            Authorization:
                'Bearer '+localStorage.getItem("authToken"),
            },
            data: payloadData,
        };
        
        axios(config)
            .then(function (response) {
            console.log('wp/v2/reference: testApiCode->then', response);
                if (response.status === 201 && response.statusText === 'Created') {                    
                    let successMessage = document.querySelector('.interested-investment-form');
                    successMessage.innerHTML = "<div style='color:green'><b>Thank you</b></div>";                    
                    setState({isInterestedIndicated:!state.isInterestedIndicated});
                    // setTimeout(function(){
                    //     setState({showpopup:!state.showpopup});
                    // }, 2000);
                }
            })
            .catch(function (error) {
                console.log('wp/v2/reference: testApiCode->catch', error);
            });
    }

    if(deal){
        const { isInterestedIndicated } = state;
        const { dealId, dealData, isLoaded } = deal;
        const { title, content, acf } = dealData;
        const { documents, image_for_deal_list_thumbnail } = acf;

        console.log("isInterestedIndicatedddddddddddddddddddddddddddd",isInterestedIndicated);

        return(
            <main>                
                <div className="container">
                    <div className="deal-details-wrapper">
                        <div className="breadcrumb-wrapper">
                            <button onClick={() => backToMarketplace()} type="button" className="btn btn-clear has-icon color-medium">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                    className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                <span>Back</span>
                            </button>
                        </div>
                        <div className="deal-header">
                            <div className="deal-title">
                                <h1>{title.rendered}</h1>
                                <div className="deal-meta">
                                    <span>{acf.city}, {acf.state}</span>                                        
                                </div>
                            </div>
                            <div className="deal-options">
                                {acf.deal_status === 'open' ?
                                    <div className="popover-wrapper">
                                        { isInterestedIndicated ?
                                            <button type="button" style={{backgroundColor: "green",color:"#fff",cursor:"default"}} className="btn btn-success has-icon" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                                    className="bi bi-star" viewBox="0 0 16 16">
                                                    <path
                                                        d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.523-3.356c.329-.314.158-.888-.283-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767l-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288l1.847-3.658 1.846 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.564.564 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                                </svg>
                                                <span>Interest Indicated</span>
                                            </button>
                                        :
                                            <button type="button" className="btn btn-dark-o has-icon" onClick={()=>{setState({showpopup:!state.showpopup})}} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                                    className="bi bi-star" viewBox="0 0 16 16">
                                                    <path
                                                        d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.523-3.356c.329-.314.158-.888-.283-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767l-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288l1.847-3.658 1.846 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.564.564 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                                </svg>
                                                <span>Indicate Interest</span>
                                            </button>
                                        }
                                        {
                                            state.showpopup ? 
                                            <div className="popover">
                                                <form onSubmit={handleSubmit(onSubmitInterest)}>
                                                    <div className="popover-content">
                                                        <div className="interested-investment-form">
                                                            <div className="field-group">
                                                                <label for="interestedAmt" className="">
                                                                    Interested Investment Amount
                                                                </label>
                                                                <input type="text" className="field-input" placeholder="Enter Amount" id="interestedamount" name="interestedamount" ref={register({required: "Amount Required",pattern: {value: /^\d*$/i, message: "Invalid Amount"}, minLength: {value:0,message:"Too Short"}, maxLength: 6})} />
                                                                {errors.interestedamount && <p className="field-error">{errors.interestedamount.message}</p>}
                                                            </div>
                                                            <div className="field-group">
                                                                <input type="hidden" name="starrating" id="starrating" ref={register()} />
                                                                <input type="hidden" name="dealtitle" id="dealtitle" defaultValue={title.rendered} ref={register()} />
                                                                <span>Not likely </span><StarRating /><span> Highly likely</span>
                                                            </div>                                                        
                                                            <div className="field-group">
                                                                <label for="interestedAmt" className="">
                                                                    Do you have any questions?
                                                                </label>
                                                                <textarea className="field-input" id="interestedquestions" name="interestedquestions" ref={register()}></textarea>
                                                            </div>
                                                            <div className="text-right">
                                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div> : null
                                        }
                                    </div> :
                                    ''
                                }
                            </div>
                        </div>
                        <div className="deal-content">
                            <div className="deal-content-left">
                                <div className="deal-gallery">
                                    <div className="deal-img">
                                        <img src={acf.image_for_deal_list_thumbnail.url} />
                                    </div>
                                </div>
                            </div>
                            <div className="deal-content-right">
                                <div className="deal-highlists">
                                    <div className="deal-sponsor">
                                        <h3>{acf.sponsor}</h3>
                                    </div>
                                    <div className="deal-highlight-count">
                                        <span>Targeted Investor IRR</span><br />
                                        <strong className="count">{acf.targeted_investor_irr}</strong>
                                    </div>
                                    <div className="deal-highlight-count">
                                        <span>Targeted Equity Multiple</span><br />
                                        <strong className="count">{acf.targeted_equity_multiple}</strong>
                                    </div>
                                    <div className="deal-highlight-count">
                                        <span>Targeted Average Cash Yield</span><br />
                                        <strong className="count">{acf.targeted_average_cash_yield}</strong>
                                    </div>
                                    <div className="deal-highlight-count">                                        
                                        <span>Targeted Investment Period</span><br />
                                        <strong className="count">{acf.targeted_investment_period}</strong>                                        
                                    </div>
                                    <div className="deal-highlight-count">
                                        <span>Deal Status</span><br />
                                        <strong className="count">
                                        {(() => {
                                            switch (acf.deal_status) {
                                            case "open": return <span style={{color: "green"}}>Active</span>;
                                            case "closed-raised": return <span style={{color: "blue"}}>Successfully Raised</span>;
                                            case "closed-completed":  return <span style={{color: "blue"}}>Completed</span>;
                                            default:      return "";
                                            }
                                        })()}
                                        </strong>
                                    </div>
                                    {acf.deal_status === 'open' && acf.invest_link_published && acf.invest_link ? 
                                        <div className="deal-action">
                                            <a target="_blank" href={acf.invest_link}><button type="button" className="btn btn-primary">Invest</button></a>
                                        </div>                                        
                                        :
                                            <>
                                            {acf.deal_status === 'closed-completed' ? 
                                                ''
                                                :
                                                <div className="deal-action">
                                                    <button type="button" className="btn btn-invest-disabled" disabled>Invest</button>
                                                </div>
                                            }
                                            </>
                                        }
                                </div>
                            </div>
                        </div>
                        <div className="deal-content">
                            <div className="deal-widget">
                                <h4 className="deal-widget-title">Documents</h4>
                                <div className="deal-widget-content">                                        
                                    <a href={documents.url} target="_blank" >PDF Document</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
    return (
        <div>
            
        </div>
    )   
}

export default DealDetails;