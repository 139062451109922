import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import axios from 'axios';
import Signup from './Signup';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
Modal.setAppElement('#root');

SwiperCore.use([Autoplay]);

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

//export default class Home extends Component {
const Home = (props) => {
    // constructor(props){
    //     super(props);  
    //     //console.log(localStorage.getItem('authName'));
    // }

    localStorage.setItem("getStartedEmail", '');
    const [state, setState] = useState({});
    const history = useHistory();
    //console.log(this.state);
    const { register, handleSubmit, errors } = useForm();
    const name = props.user;
    const currLoc = window.location.pathname;

    const onSubmitReference = (data) => {
        console.log("form submitted");
        console.log(localStorage.getItem('authUID'));
        console.log(localStorage.getItem('authToken'));
        console.log("data", data);

        localStorage.setItem("getStartedEmail", data.getstartedemail);
        console.log(history);
        //window.location.href = '/signup';
        history.push("/signup");
        console.log(localStorage.getItem('getStartedEmail'));
    }


    // handleSuccessfulAuth(data){
    //     this.props.history.push("/marketplace");
    // }

    // render(){

    //console.log("this.props", this.props);

    // if(this.props.user !== null){
    //     return (
    //         <h2>{this.props.user}</h2>
    //     )
    // }

    // if(localStorage.getItem('authName') != ''){
    //     this.state = {
    //         isLoggedIn: true,
    //         authName: localStorage.getItem('authName')
    //     }
    // }

    // console.log(this.state);

    // const { isLoggedIn, authName } = this.state;


    const [modalIsOpen, setIsOpen] = React.useState(false);
    let subtitle;
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <main style={{ paddingTop: '0px' }}>

            <section className="banner">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    onSlideChange={() => console.log('slide changed')}
                    onSwiper={(swiper) => console.log(swiper)}
                    effect="fade"
                    autoplay={{ delay: '2500', pauseOnMouseEnter: true }}
                >
                    <SwiperSlide>
                        <div className="banner-image">
                            <img src="images/banner1.jpg" alt="" />
                        </div>
                        <div className="banner-content">
                            {/* <h1>Commercial real estate invesment democratized</h1> */}
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="banner-image">
                            <img src="images/banner2.jpg" alt="" />
                        </div>
                        <div className="banner-content">
                            {/* <h1>Commercial real estate invesment democratized</h1> */}

                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="banner-image">
                            <img src="images/banner3.jpg" alt="" />
                        </div>
                        <div className="banner-content">
                            {/* <h1>Commercial real estate invesment democratized</h1> */}

                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="banner-image">
                            <img src="images/banner4.jpg" alt="" />
                        </div>
                        <div className="banner-content">
                            {/* <h1>Commercial real estate invesment democratized</h1> */}

                        </div>
                    </SwiperSlide>
                </Swiper>
                {/* <div className="cta-form">
                    {
                        localStorage.getItem('getStartedEmail') || localStorage.getItem('authToken') ?
                            ''
                            :
                            <form onSubmit={handleSubmit(onSubmitReference)} >
                                <div className="form-cta">
                                    <input type="text" className="field-input" placeholder="Enter your email" id="getstartedemail" name="getstartedemail" ref={register({ required: "Email Required", pattern: { value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/, message: "*Invalid Email" }, minLength: { value: 10, message: "*Too Short" }, maxLength: 80 })} />
                                    <button type="submit" className="btn btn-primary">Get Started</button>
                                </div>
                                {errors.getstartedemail && <p className="field-error">{errors.getstartedemail.message}</p>}
                            </form>
                    }
                </div> */}
                <div className="cta-form">
                    <div className="d-flex ai-center jc-center">
                        {/* <a href="https://techtownequity.invportal.com/signup" className="btn btn-primary">Get Started</a> */}
                        <button className="btn btn-primary" onClick={openModal}>Get Started</button>
                    </div>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    // style={customStyles}
                    contentLabel="Sign Up"
                >
                    <button onClick={closeModal} className="btn modal-close-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                        </svg>
                    </button>
                    <iframe title="signUp" width="100%" height="950px" src="https://techtownequity.invportal.com/signup" frameBorder="0"></iframe>
                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}

                </Modal>
            </section>
            <section className="our-focus">
                <div className="container">
                    <h2 className="text-center">Our Focus</h2>
                    <div className="portfolio-wrapper">
                        <div className="portfolio-col">
                            <a className="portfolio-img square">
                                <img src="images/warehouse.jpg" alt="" />
                                <div className="img-caption">
                                    <span>
                                        Warehouse
                                    </span>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            className="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                        </svg> */}
                                </div>
                            </a>
                            <a className="portfolio-img rectangle">
                                <img src="images/multi-family.jpg" alt="" />
                                <div className="img-caption">
                                    <span>
                                        Multi-family
                                    </span>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            className="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                        </svg> */}
                                </div>
                            </a>
                        </div>
                        <div className="portfolio-col">
                            <a className="portfolio-img rectangle">
                                <img src="images/retail1.jpg" alt="" />
                                <div className="img-caption">
                                    <span>
                                        Retail
                                    </span>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            className="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                        </svg> */}
                                </div>
                            </a>
                            <a className="portfolio-img square">
                                <img src="images/mixed-use.jpg" alt="" />
                                <div className="img-caption">
                                    <span>
                                        Mixed use
                                    </span>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            className="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                        </svg> */}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
    // }
}

export default Home;