import React, { Component, PropTypes, useState } from "react";
import { useForm } from 'react-hook-form';
import { Link, Redirect, useHistory } from 'react-router-dom';
// import Recaptcha from "react-recaptcha";
import axios from 'axios';

const Signup = () => {

  // constructor(props) {
  //   super(props);

  //   const { register, handleSubmit, errors } = useForm();

  //   this.handleSubscribe = this.handleSubscribe.bind(this);
  //   this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
  //   this.verifyCallback = this.verifyCallback.bind(this);



  //   this.state = {
  //     email: "",
  //     password: "",
  //     password_confirmation: "",
  //     registrationErrors: ""
  //   };

  //   this.handleSubmit = this.handleSubmit.bind(this);
  //   this.handleChange = this.handleChange.bind(this);
  //   this.handleSuccessfulAuth = this.handleSuccessfulAuth.bind(this);
  //   //this.handleLogin = this.handleLogin.bind(this);
  // }

  const basePath = localStorage.getItem("basePath");

  const getStartedEmail = localStorage.getItem('getStartedEmail');

  const { register, watch, handleSubmit, errors } = useForm();

  // const recaptchaLoaded = () => {
  //   console.log('captcha successfully loaded!');
  // }

  // const handleSubscribe = () => {
  //   if(this.state.isVerified){
  //     alert("You have successfully subscribed!");
  //   }else{
  //     alert("Please verify that you are a human!");
  //   }
  // }

  // const handleSuccessfulAuth = (data) => {
  //   this.handleLogin(data);
  //   //this.props.history.push("/marketplace");
  // }

  // const verifyCallback = (response) => {
  //   if(response){
  //     this.setState({
  //       isVerified: true
  //     })
  //   }
  // }

  // const handleChange = (event) => {
  //   this.setState({
  //     [event.target.name]: event.target.value
  //   });
  // }

  let history = useHistory();

  const onSubmit = (data, e) => {
    //console.log("form submitted");
    //console.log("data",data);
    axios.post(`${basePath}?rest_route=/wp/v2/users/register`, {
      username: data.email,
      email: data.email,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone,
      are_you_above_18_yrs_of_age: data.isAbove18,
      are_you_an_accredited_investor: data.isAccredited
    },
      { withCredentials: true }
    )
      .then(response => {
        console.log(response);
        if (response.data.code === 200 && response.data.id > 0) {
          e.target.reset();

          axios.post(`${basePath}?rest_route=/wcra/v1/regusr`, {
            email: data.email
          },
            { withCredentials: true }
          )
            .then(response => {
              console.log("responseeeeeeeeeeeeeeeeeeeeeeeeee", response);
              //if (response.data.code === 200 && response.data.id > 0) {
              //e.target.reset();
              console.log("user registered successfully");
              let successMessage = document.querySelector('.successMsg');
              successMessage.innerHTML = "<h2>Thank you for registering!</h2><h3><i>You will receive an email once your account is activated.</i></h3>";
              //history.push("/login");
              //this.props.history.push('/login');            
              //}
            })
            .catch(error => {
              console.log("registration errorrrrrrrrrrrrrr", error);
              //   if(error.response.data.message === "Username already exists, please enter another username"){
              //     let errorMessage = error.response.data.message;
              //     errorMessage = errorMessage.replace("Username", "Email");
              //     errorMessage = errorMessage.replace("username", "email");
              //     console.log("errorMessage",errorMessage);
              //     let errorMsg = document.querySelector('.errorMsg');
              //     errorMsg.innerHTML = errorMessage;

              //   }
              console.log("registration error responseeeeeeeee", error.response.data.message);
              // let successMessage = document.querySelector('.success-message');
              //       successMessage.innerHTML = JSON.stringify(error);
            });

          console.log("user registered successfully");
          let successMessage = document.querySelector('.successMsg');
          successMessage.innerHTML = "<h2>Thank you for registering!</h2><h3><i>You will receive an email once your account is activated.</i></h3>";
          setTimeout(() => {
            successMessage.innerHTML = "";
          }, 5000);
          //history.push("/login");
          //this.props.history.push('/login');            
        }
      })
      .catch(error => {
        console.log("registration error", error);
        if (error.response.data.message === "Username already exists, please enter another username") {
          let errorMessage = error.response.data.message;
          errorMessage = errorMessage.replace("Username", "Email");
          errorMessage = errorMessage.replace("username", "email");
          console.log("errorMessage", errorMessage);
          let errorMsg = document.querySelector('.errorMsg');
          errorMsg.innerHTML = errorMessage;
          setTimeout(() => {
            errorMsg.innerHTML = "";
          }, 5000);

        }
        console.log("registration error response", error.response.data.message);
        // let successMessage = document.querySelector('.success-message');
        //       successMessage.innerHTML = JSON.stringify(error);
      });
  }


  return (
    <main className="main-wrapper">
      {/* <h2>Status: {this.props.loggedInStatus}</h2> */}
      <div className="card auth-card">
        <div className="card-content">
          <div className="errorMsg" style={{ color: 'red' }}>
            <label></label>
          </div>
          <div className="successMsg" style={{ color: 'green' }}>
            <label></label>
          </div>
          {/* <img src="/images/techtown-logo.svg" alt="" width="120" /> */}
          <h1 className="fw-bold">Sign up to start investing</h1>
          {/* <form onSubmit={this.handleSubmit}> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="multi-field-group d-flex">
              <div className="field-group">
                <input type="text" className="field-input" name="firstName" id="firstName" placeholder="First Name" ref={register({ required: "First Name Required", pattern: { value: /^[a-z]+$/i, message: "Please Enter Alphabetics only" }, minLength: { value: 3, message: "Too Short" }, maxLength: 80 })} />
                {errors.firstName && <p className="field-error">{errors.firstName.message}</p>}
                {/* <input type="text" className="field-input" name="firstName" id="firstName" placeholder="First Name" required value={this.state.firstName} onChange={this.handleChange}  /> */}
              </div>
              <div className="field-group">
                <input type="text" className="field-input" name="lastName" id="lastName" placeholder="Last Name" ref={register({ required: "Last Name Required", pattern: { value: /^[a-z]+$/i, message: "Please Enter Alphabetics only" }, minLength: { value: 3, message: "Too Short" }, maxLength: 80 })} />
                {errors.lastName && <p className="field-error">{errors.lastName.message}</p>}
                {/* <input type="text" className="field-input" name="lastName" id="lastName" placeholder="Last Name" required value={this.state.lastName} onChange={this.handleChange} /> */}
              </div>
            </div>
            <div className="field-group">
              <input defaultValue={getStartedEmail} type="text" className="field-input" name="email" id="email" placeholder="Email" ref={register({ required: "Email Required", pattern: { value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/, message: "Invalid Email" }, minLength: { value: 10, message: "Too Short" }, maxLength: 80 })} />
              {errors.email && <p className="field-error">{errors.email.message}</p>}
              {/* <input type="email" className="field-input" name="email" id="email" placeholder="Email" required value={this.state.email} onChange={this.handleChange} /> */}
            </div>
            <div className="field-group">
              <input type="text" className="field-input" name="phone" id="phone" placeholder="Phone" ref={register({ required: "Phone Required", pattern: { value: /^[0-9]+$/i, message: "Please Enter Numerics only" }, minLength: { value: 9, message: "Too Short" }, maxLength: { value: 12, message: "Too high" } })} />
              {errors.phone && <p className="field-error">{errors.phone.message}</p>}
              {/* <input type="text" className="field-input" name="phone" id="phone" placeholder="Phone" required value={this.state.phone} onChange={this.handleChange} /> */}
            </div>
            <div className="field-group">
              {/* <input type="password" className="field-input" name="password" id="password" placeholder="Password" required value={this.state.password} onChange={this.handleChange} />                               */}
              <input type="password" className="field-input" name="password" id="password" placeholder="Password" ref={register({ required: "Password Required", minLength: { value: 4, message: "Too Short" }, maxLength: 80 })} />
              {errors.password && <p className="field-error">{errors.password.message}</p>}
            </div>
            <div className="field-group">
              {/* <input type="password" className="field-input" name="confirmPassword" id="confirmPassword" placeholder="Re-type Password" required value={this.state.confirmPassword} onChange={this.handleChange} /> */}
              <input type="password" className="field-input" name="confirmPassword" id="confirmPassword" placeholder="Re-type Password" ref={register({ validate: (value) => value === watch('password') || "Passwords don't match.", required: "Confirm Password Required", minLength: { value: 4, message: "Too Short" }, maxLength: 80 })} />
              {errors.confirmPassword && <p className="field-error">{errors.confirmPassword.message}</p>}
            </div>
            <div className="field-group checkbox-group">
              <label className="field-label-check">
                <input type="checkbox" className="field-check" name="isAbove18" id="isAbove18" ref={register} />
                {/* <input type="checkbox" className="field-check" name="isAbove18" id="isAbove18" value={this.state.isAbove18} onChange={this.handleChange} /> */}
                <span>Are you above 18 years of age?</span>
              </label>
              <label className="field-label-check">
                <input type="checkbox" className="field-check" name="isAccredited" id="isAccredited" ref={register} />
                {/* <input type="checkbox" className="field-check" name="isAccredited" id="isAccredited" value={this.state.isAccredited} onChange={this.handleChange} /> */}
                <span>Are you an accredited investor?</span>
              </label>
            </div>

            {/* <input type="text" placeholder="sadfasfas@dsafsd.asdf" />
                             <div className="" onClick={this.handleSubscribe} >
                               Subscribe
                             </div> */}

            {/* <Recaptcha
                              sitekey="6LfCc3oaAAAAAGMqZVkBj3RKSoRQxa48vBudPp28"
                              render="explicit"
                              onloadCallback={this.recaptchaLoaded}
                              verifyCallback={this.verifyCallback}
                            /> */}

            <div className="text-right">
              <input type="submit" className="btn btn-primary" value="Sign up" />
            </div>
          </form>
        </div>
      </div>
      <div className="card">
        <div className="card-content text-center">
          <p className="m-0">Already an user? <Link className="fw-bold" to="/login">Sign in</Link></p>
        </div>
      </div>
      {/* <div className="auth-footer">
              <div>
                  <Link to="/">Back to Home</Link>                    
              </div>
              <div>                    
                  <Link to="/">Privacy Policy</Link>
                  <Link to="/">Terms of use</Link>                    
              </div>
          </div> */}
    </main>
  );
}

export default Signup;