import PropTypes from 'prop-types';
import { Link, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';

const DealItem = (props) => {

    let history = useHistory();



    const { id, title, acf } = props.deal;

    const dealDetail = (data) => {
        history.push("/dealdetails/" + data.id);
    }

    return (
        <div className="deal-item" onClick={() => { dealDetail({ id }); }} >
            <div className="deal-thumbnail">
                <div className="thumbnail-wrapper">
                    <img src={acf.image_for_deal_list_thumbnail.url} alt=""/>
                </div>
            </div>
            <div className="deal-summary">
                <div className="deal-meta">
                    <span className="meta-info-tags primary">{acf.city}</span>
                    <span className="meta-info-tags primary">{acf.state}</span>
                    <span className="meta-info-tags deal-status success">
                        {/* <small>Deal Status</small> */}
                            {(() => {
                                switch (acf.deal_status) {
                                    case "open": return <strong>Active</strong>;
                                    case "closed-raised": return <strong>Successfully Raised</strong>;
                                    case "closed-completed": return <strong>Completed</strong>;
                                    default: return "";
                                }
                            })()}
                    </span>
                    {/* <span className="divider-dot"></span>
                    <span>Mixed use</span> */}
                </div>
                <h2 className="deal-title">
                    <strong>{title.rendered}</strong>
                </h2>
                <h3 className="deal-sponsor">
                    {acf.sponsor}
                </h3>
                <div className="deal-highlists">
                    <div className="deal-highlight-count">
                        <strong className="count">{acf.targeted_investor_irr}</strong>
                        <span>Targeted IRR</span>
                    </div>
                    <div className="deal-highlight-count">
                        <strong className="count">{acf.targeted_equity_multiple}</strong>
                        <span>Targeted Equity Multiple</span>
                    </div>
                    <div className="deal-highlight-count">
                        <strong className="count">{acf.targeted_average_cash_yield}</strong>
                        <span>Targeted Average Cash Yield</span>
                    </div>
                    <div className="deal-highlight-count">
                        <strong className="count">{acf.targeted_investment_period}</strong>
                        <span>Targeted Investment Period</span>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default DealItem;
