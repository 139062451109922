import React, { Component } from 'react';
import DealItem from './DealItem';
import axios from 'axios';
import { 
    BrowserRouter as Router, 
    Route, 
    Link, 
    Switch 
} from 'react-router-dom'; 

const basePath = localStorage.getItem("basePath");

export default class Deals extends Component {
    
    state = {
        deals: [],
        isLoaded: false
    }

    // constructor(props){
    //     super(props);        
    //     this.state = {}
    // }

    componentDidMount() {
        axios.get(`${basePath}?rest_route=/wp/v2/deals`)
        .then(res => this.setState({
            dealsOriginal: res.data,
            deals: res.data.filter(deal => deal.acf.deal_status === "open"),
            deal_status: "open",
            isLoaded: true
        }))
        .catch(err => console.log(err));
    }
    


    handleClick = event => {

        // console.log(event);
        // console.log(this.state.dealsOriginal);

        const deal_status = event.target.id;
        let filterDeals = [];
        
        if(deal_status === "open"){
            filterDeals = this.state.dealsOriginal.filter(deal => deal.acf.deal_status === deal_status)
        } else{
            filterDeals = this.state.dealsOriginal.filter(deal => deal.acf.deal_status !== "open")
        }
        
        this.setState({deals: filterDeals, deal_status: deal_status})
    }

    
    render() {
        const { deals, isLoaded } = this.state;
         if(isLoaded) {

            //const renderAll = this.state.filterCoffee.map(coffee => <li key={coffee.name}>{coffee.name}</li>)
            const renderAll = deals.map(deal => <DealItem key={deal.id} deal={deal} />)

            return (
                <div className="container">                              
                    <div className="filter-wrapper">
                        {/* <div className="filters">
                            <div className="search-filter">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="15.998" viewBox="0 0 16.001 15.998"><path d="M11.742,10.344a6.5,6.5,0,1,0-1.4,1.4h0a1.17,1.17,0,0,0,.1.115l3.85,3.85a1,1,0,0,0,1.415-1.414l-3.85-3.85a1.007,1.007,0,0,0-.115-.1ZM12,6.5A5.5,5.5,0,1,1,6.5,1,5.5,5.5,0,0,1,12,6.5Z" transform="translate(0.001 -0.002)" fill="#2a4065"/></svg>
                                <input type="text" className="search-input" placeholder="Search" />
                            </div>
                            <div className="filter-option">
                                <label for="dealsStatus">Showing</label>
                                <select name="dealsStatus" id="dealsStatus">
                                    <option value="1" selected>Active deals</option>
                                    <option value="1" selected>Past deals</option>
                                </select>
                            </div>
                            <div className="filter-option">
                                <label for="location">Location</label>
                                <select name="location" id="location">
                                    <option value="1" selected>Active deals</option>
                                    <option value="1" selected>Past deals</option>
                                </select>
                            </div>
                            <div className="filter-option">
                                <label for="sortDeals">Sort by</label>
                                <select name="sortDeals" id="sortDeals">
                                    <option value="1" selected>Active deals</option>
                                    <option value="1" selected>Past deals</option>
                                </select>
                            </div>
                        </div>
                        <div className="category-filter"></div> */}
                        <div className="nav-tabs">                            
                            {this.state.deal_status === 'open' ? 
                                <div className="tab-item active">
                                    <a href="#" id="open" onClick={this.handleClick}>Active Deals</a>
                                </div>
                                :
                                <div className="tab-item">
                                    <a href="#" id="open" onClick={this.handleClick}>Active Deals</a>
                                </div>
                            }
                            {this.state.deal_status === 'closed' ? 
                                <div className="tab-item active">
                                    <a href="#" id="closed" onClick={this.handleClick}>Past Deals</a>
                                </div>
                                :
                                <div className="tab-item">
                                    <a href="#" id="closed" onClick={this.handleClick}>Past Deals</a>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="deals-grid">
                        {renderAll}
                        {/* {deals.map(deal =>(   
                            <DealItem key={deal.id} deal={deal} />
                        ))} */}
                    </div>
                </div>
            );
        }        
        return (
            <div>
                
            </div>
        )
    }
}