import React, { Component, PropTypes, useState } from "react";
import { useForm } from 'react-hook-form';
import { Link, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';

const ContactUS = () => {

    const basePath = localStorage.getItem("basePath");

    const { register, watch, handleSubmit, errors } = useForm();

    let history = useHistory();

    const onSubmit = (data, e) => {
        //console.log("form submitted");
        console.log("data", data);
        axios.post(`${basePath}?rest_route=/wcra/v1/contactus`, {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            phone: data.phone,
            comments: data.comments
        },
            //{ withCredentials: true }
        )
            .then(response => {
                e.target.reset();
                if (response.data) {
                    e.target.reset();
                    let successMessage = document.querySelector('.successMsg');
                    successMessage.innerHTML = "<h2>Thank you for contacting us!</h2>";
                    setTimeout(() => {
                        successMessage.innerHTML = "";
                      }, 5000);
                }
            })
            .catch(error => {
                console.log("registration errorrrrrrrrrrrrrr", error);
            });

    }


    return (
        <main>
            <section className="page-banner" style={{ backgroundColor: '#253646' }}>
                <div className="page-banner-bg">
                    <img src="images/contact-us.jpg" alt="" />
                </div>
                <div className="container">
                    <div className="page-title light-text" style={{ width: '50%' }}>
                        <h1>Contact us</h1>
                        <p className="subtitle">Our team is standing by to assist you with all of your commercial investment needs. Use the contact form below to get started.</p>
                    </div>
                </div>
            </section>
            <section className="contact-wrapper">
                <div className="container">
                    <div className="contact-content">
                        <div className="contact-form">
                            <div className="successMsg" style={{ color: 'green' }}>
                                <label></label>
                            </div>
                            <p> <strong>Submit a question or comment</strong></p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="multi-field-group d-flex">
                                    <div className="field-group">
                                        <input type="text" className="field-input" name="firstName" id="firstName" placeholder="First Name" ref={register({ required: "First Name Required", pattern: { value: /^[a-z]+$/i, message: "Please Enter Valid Firstname" }, minLength: { value: 3, message: "Too Short" }, maxLength: 80 })} />
                                        {errors.firstName && <p className="field-error">{errors.firstName.message}</p>}
                                    </div>
                                    <div className="field-group">
                                        <input type="text" className="field-input" name="lastName" id="lastName" placeholder="Last Name" ref={register({ required: "Last Name Required", pattern: { value: /^[a-z]+$/i, message: "Please Enter Valid Lastname" }, minLength: { value: 3, message: "Too Short" }, maxLength: 80 })} />
                                        {errors.lastName && <p className="field-error">{errors.lastName.message}</p>}
                                    </div>
                                </div>
                                <div className="multi-field-group d-flex">
                                    <div className="field-group">
                                        <input type="text" className="field-input" name="email" id="email" placeholder="Email" ref={register({ required: "Email Required", pattern: { value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/, message: "Invalid Email" }, minLength: { value: 10, message: "Too Short" }, maxLength: 80 })} />
                                        {errors.email && <p className="field-error">{errors.email.message}</p>}
                                    </div>
                                    <div className="field-group">
                                        <input type="text" className="field-input" name="phone" id="phone" placeholder="Phone" ref={register({ required: "Phone Required", pattern: { value: /^[0-9]+$/i, message: "Please Enter Valid Phone Number" }, minLength: { value: 9, message: "Too Short" }, maxLength: { value: 15, message: "Too high" } })} />
                                        {errors.phone && <p className="field-error">{errors.phone.message}</p>}
                                    </div>
                                </div>
                                {/* <div className="field-group">                                    
                                    <select name="reason" id="reason" className="field-input">
                                        <option value="0">Reason for contacting</option>
                                        <option value="1">I have a question</option>
                                        <option value="2">Feedback</option>
                                    </select>
                                </div>
                                <div className="field-group">                                    
                                    <select name="preference" id="preference" className="field-input">
                                        <option value="0">Contact Preference</option>
                                        <option value="1">Phone</option>
                                        <option value="2">Email</option>
                                    </select>
                                </div> */}
                                <div className="field-group">
                                    <textarea className="field-input" name="comments" id="comments" cols="30" rows="10"
                                        placeholder="Question/Comment" ref={register({})} ></textarea>
                                </div>
                                <div className="text-right">
                                    <input type="submit" className="btn btn-primary" value="Submit" />
                                </div>
                            </form>
                        </div>
                        <div className="divider">
                            <span>or</span>
                        </div>
                        <div className="contact-details">
                            <div className="contact-details-row contact-email d-flex">
                                <div className="contact-icon">
                                    <img src="images/email.png" alt="" />
                                </div>
                                <div>
                                    <strong>Send us an e-mail</strong>
                                    <div className="contact-type-text">
                                        <a href="mailto:ir@techtownequity.com">ir@techtownequity.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-details-row contact-phone d-flex">
                                <div className="contact-icon">
                                    <img src="images/phone.png" alt="" />
                                </div>
                                <div>
                                    <strong>Give us a call</strong>
                                    <div className="contact-type-text">
                                        <a href="tel:5125931635">(512) 593-1635</a>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-details-row contact-address  d-flex">
                                <div className="contact-icon">
                                    <img src="images/address.png" alt="" />
                                </div>
                                <div>
                                    <strong>Address</strong>
                                    <div className="contact-type-text">
                                        <p className="m-0">
                                            11801 Domain Blvd - 3rd floor <br />
                                        Austin
                                        TX 78758
                                        <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default ContactUS;