import { BrowserRouter as Router, Route, Link, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import React, { Component, useState } from 'react';
const Footer = () => {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    // let subtitle;    
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const [TermsIsOpen, termsIsOpen] = React.useState(false);
    //   let subtitle;
    function openTerms() {
        termsIsOpen(true);
    }

    function closeTerms() {
        termsIsOpen(false);
    }

    return (
        <footer>
            <div className="container">
                <div className="footer-col">
                    <div className="footer-brand">
                        <Link className="brand-logo" to="/"><img src="../images/techtown-logo.svg" alt="Tech Town" width="120" /></Link>
                    </div>
                    {/* <div className="footer-social-links">
                        <a href="#" className="social-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g transform="translate(-6 -6)">
                                    <path
                                        d="M30,26.667A3.333,3.333,0,0,1,26.667,30H9.333A3.333,3.333,0,0,1,6,26.667V9.333A3.333,3.333,0,0,1,9.333,6H26.667A3.333,3.333,0,0,1,30,9.333Z"
                                        fill="#2a4065" />
                                    <path
                                        d="M30.578,21H28.333v8.667H25V21H23V18.333h2V16.727C25,14.388,25.974,13,28.729,13H31v2.667H29.477c-1.073,0-1.143.4-1.143,1.148v1.518H31Z"
                                        transform="translate(-5.667 -2.333)" fill="#fff" />
                                </g>
                            </svg>
                        </a>
                        <a href="#" className="social-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g transform="translate(-6 -6)">
                                    <path
                                        d="M30,26.667A3.333,3.333,0,0,1,26.667,30H9.333A3.333,3.333,0,0,1,6,26.667V9.333A3.333,3.333,0,0,1,9.333,6H26.667A3.333,3.333,0,0,1,30,9.333Z"
                                        fill="#2a4065" />
                                    <path d="M12,19h3.333V30.333H12Z" transform="translate(-2 -4.333)" fill="#fff" />
                                    <path d="M13.656,15.333h-.018A1.667,1.667,0,0,1,13.677,12a1.667,1.667,0,0,1-.021,3.333Z"
                                        transform="translate(-2 -2)" fill="#fff" />
                                    <path
                                        d="M30.667,30.333H27.333V24.268A2.175,2.175,0,0,0,25.206,21.8a1.912,1.912,0,0,0-1.8,1.326,4.8,4.8,0,0,0-.068,1.206v6H20V19h3.333v1.745A3.374,3.374,0,0,1,26.492,19c2.385,0,4.174,1.5,4.174,4.849Z"
                                        transform="translate(-4.667 -4.333)" fill="#fff" />
                                </g>
                            </svg>
                        </a>
                        <a href="#" className="social-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.83" height="20" viewBox="0 0 24.83 20">
                                <g transform="translate(-5.996 -9)">
                                    <path
                                        d="M30.826,11.365a10.306,10.306,0,0,1-2.928.8,5.1,5.1,0,0,0,2.241-2.8A10.21,10.21,0,0,1,26.9,10.592,5.082,5.082,0,0,0,18.227,15.2a14.5,14.5,0,0,1-10.5-5.278,4.981,4.981,0,0,0-.69,2.543,5.035,5.035,0,0,0,2.266,4.2A5.17,5.17,0,0,1,7,16.037V16.1A5.068,5.068,0,0,0,11.08,21.05a5.1,5.1,0,0,1-1.339.18,5.336,5.336,0,0,1-.959-.094,5.1,5.1,0,0,0,4.758,3.508,10.3,10.3,0,0,1-6.328,2.163A10.032,10.032,0,0,1,6,26.734,14.568,14.568,0,0,0,13.806,29,14.331,14.331,0,0,0,28.3,14.63c0-.218-.008-.436-.019-.649a10.079,10.079,0,0,0,2.543-2.616"
                                        fill="#2a4065" />
                                </g>
                            </svg>
                        </a>
                    </div> */}
                    <p>&copy; 2021 TechTown Equity LLC</p>
                </div>
                <div className="footer-col">
                    <h4>Company</h4>
                    <div className="footer-links">
                        <Link to="/about">About us</Link>
                    </div>
                </div>
                <div className="footer-col">
                    <h4>Help</h4>
                    <div className="footer-links">
                        <Link to="/contact">Contact us</Link>
                    </div>
                </div>
                <div className="footer-col">
                    <h4>Legal</h4>
                    <div className="footer-links">
                        {/* <Link to="/privacy-policy">Privacy Policy</Link> */}
                        {/* <a href="https://techtownequity.invportal.com/privacy-policy" className="nav-link">Privacy Policy</a>                    */}
                        <a className="nav-link" onClick={openModal}>Privacy Policy</a>
                        {/* <Link to="/terms">Terms of use</Link>      */}
                        <a className="nav-link" onClick={openTerms}>Terms of use</a>
                        {/* <a href="https://techtownequity.invportal.com/terms-and-conditions" className="nav-link">Terms of use</a>                    */}
                    </div>
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    // style={customStyles}
                    contentLabel="Privacy Policy"
                >
                    <button onClick={closeModal} className="btn modal-close-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                        </svg>
                    </button>
                    <iframe title="Privacy Policy" width="100%" height="950px" src="https://techtownequity.invportal.com/privacy-policy" frameBorder="0"></iframe>
                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                </Modal>
                <Modal
                    isOpen={TermsIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeTerms}
                    // style={customStyles}
                    contentLabel="Terms"
                >
                    <button onClick={closeTerms} className="btn modal-close-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                        </svg>
                    </button>
                    <iframe title="Terms" width="100%" height="950px" src="https://techtownequity.invportal.com/terms-and-conditions" frameBorder="0"></iframe>
                    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                </Modal>
            </div>
        </footer>

    );
}

export default Footer;