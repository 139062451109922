import { 
    BrowserRouter as Router, 
    Route, 
    Link, 
    Switch, 
    useHistory
} from 'react-router-dom'; 
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const Login = props => {
    
    const { register, handleSubmit, errors } = useForm();
    const history = useHistory();
    const basePath = localStorage.getItem("basePath");

    const onSubmit = data => {        
        const email = data.email;
        axios.get(`${basePath}?rest_route=/wp/v2/users/&search=${email}`,{})
        .then(function (response){
            console.log("response.data[0].acf.is_approved", response);
            if(response.data.length > 0){
                if(response.data[0].acf.is_approved === true){
                    axios.post(`${basePath}?rest_route=/jwt-auth/v1/token`, {
                        username: data.email,
                        password: data.password
                    })
                    .then(function (response) {                        
                        let successMessage = document.querySelector('.success-message');
                        if(response.status === 200){
                            localStorage.setItem("authToken", response.data.token);
                            localStorage.setItem("authName", response.data.user_display_name);
                            localStorage.setItem("iibutton",false);                            
                            window.location.href = '/';
                        }else{
                            successMessage.innerHTML = JSON.stringify("Invalid Username/Password.");
                        }                        
                    })
                    .catch(function (error) {
                        console.log(error);
                        let errorMessage = document.querySelector('.error-message');
                        errorMessage.innerHTML = "Invalid Username/Password.";                        
                    });
                }else{
                    let errorMessage = document.querySelector('.error-message');
                    errorMessage.innerHTML = "Your account is not activated. <br />Please wait until you receive an email confirmation";
                }
            }else{
                let errorMessage = document.querySelector('.error-message');
                //errorMessage.innerHTML = "Invalid Username/Password.";
                errorMessage.innerHTML = "Your account is not activated. <br />Please wait until you receive an email confirmation";
            }            
        })
        .catch(err => console.log(err));
    }

    return (
        <main className="main-wrapper">
            <div className="card auth-card">
                <div className="card-content">
                    <div className="success-message" style={{color:'green'}}>
                        <label></label>
                    </div>
                    <div className="error-message" style={{color:'red'}}>
                        <label></label>
                    </div>
                    {/* <img src="images/techtown-logo.svg" alt="" width="120"  /> */}
                    <h1 className="fw-bold">Sign in to access your account</h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="field-group">                                    
                            <input type="text" className="field-input" name="email" id="email" placeholder="Email" ref={register({required: "Email Required",pattern: {value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/, message: "Invalid Email"}, minLength: {value:10,message:"Too Short"}, maxLength: 80})}  />
                            {errors.email && <p className="field-error">{errors.email.message}</p>}
                        </div>
                        <div className="field-group">                        
                        <input type="password" className="field-input" name="password" id="password" placeholder="Password" ref={register({required: "Password Required", minLength: {value:4,message:"Too Short"}, maxLength: 80})} />
                            {errors.password && <p className="field-error">{errors.password.message}</p>}
                        </div>
                            <Link to="/forgot-password">Forgot Password?</Link>
                        <div className="text-right">
                            <button type="submit" className="btn btn-primary">Sign in</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card">
                <div className="card-content text-center">
                    <p className="m-0">New user? <Link to="/signup" className="fw-bold">Sign up</Link></p>
                </div>
            </div>
            {/* <div className="auth-footer">
                <div>
                    <Link to="/">Back to Home</Link>
                </div>
                <div>                    
                    <Link to="/">Privacy Policy</Link>
                    <Link to="/">Terms of use</Link>
                </div>
            </div> */}
        </main>        
    );
}

export default Login;