import React, { useState } from "react";
import Modal from 'react-modal';
import axios from "axios";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

const basePath = localStorage.getItem("basePath");

const Header = (props) => {
  const [state, setState] = useState({});
  const { register, handleSubmit, errors } = useForm();
  const name = props.user;
  const currLoc = window.location.pathname;

  const [InvestmentIsOpen, investmentIsOpen] = React.useState(false);
  //   let subtitle;
  function openInvestments() {
    investmentIsOpen(true);
  }

  function closeInvestments() {
    investmentIsOpen(false);
  }

  const [LoginIsOpen, loginIsOpen] = React.useState(false);
  //   let subtitle;
  function openLogin() {
    loginIsOpen(true);
  }

  function closeLogin() {
    loginIsOpen(false);
  }

  // console.log(localStorage.getItem("authUID"));
  // console.log(localStorage.getItem("authToken"));

  const onSubmitReference = (data) => {
    console.log("form submitted");
    console.log(localStorage.getItem("authUID"));
    console.log(localStorage.getItem("authToken"));
    console.log("data", data);

    const payloadData = JSON.stringify({
      title: data.referemail,
      fields: { email: data.referemail, refered_by: localStorage.getItem("authUID"), status: 'draft' },
    });

    var config = {
      method: 'post',
      url: `${basePath}?rest_route=/wp/v2/reference`,
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem("authToken"),
      },
      data: payloadData,
    };

    axios(config)
      .then(function (response) {
        console.log('wp/v2/reference: testApiCode->then', response);
        if (response.status === 201 && response.statusText === 'Created') {
          let successMessage = document.querySelector('.refer-friend-form');
          successMessage.innerHTML = "<div style='color:green'><b>Thank you</b></div>";
          setTimeout(function () {
            setState({ showpop: !state.showpop });
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log('wp/v2/reference: testApiCode->catch', error);
      });
  };

  return (
    <header style={{ borderBottom: 1 + "px solid #999" }}>
      <div className="container">
        <div className="nav-brand">
          <Link className="brand-logo" to="/">
            <img
              src="../images/techtown-logo.svg"
              alt="Tech Town"
              width="140"
            />
          </Link>
        </div>
        {name ? (
          <nav className="nav main-nav">
            {currLoc === "/" ? (
              <Link className="nav-link" to="/">
                Home
              </Link>
            ) : (
              <Link className="nav-link" to="/">
                Home
              </Link>
            )}
            <Link className="nav-link" to="/marketplace">
              Investments
            </Link>
            <Link className="nav-link" to="/resources">Resources</Link>
            <div className="dropdown">
              <Link className="nav-link">
                <span>Company</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  className="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </Link>
              <div className="dropdown-menu">
                <Link className="nav-link" to="/about">
                  About us
                </Link>
              </div>
            </div>
            <Link className="nav-link" to="/contact">
              Contact us
            </Link>
            <div className="dropdown">
              <Link className="nav-link">
                <span>{name}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  className="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </Link>
              <div className="dropdown-menu">
                <div className="dropdown-header">
                  Signed in as{" "}
                  <span
                    style={{
                      fontWeight: "Bolder",
                      textTransform: "capitalize",
                    }}
                  >
                    {" "}
                    {name}
                  </span>
                </div>
                <hr />
                <Link className="nav-link" to="/logout">
                  Logout
                </Link>
              </div>
            </div>
            <div className="popover-wrapper">
              <Link
                className="nav-link nav-cta"
                onClick={() => {
                  setState({ showpop: !state.showpop });
                }}
              >
                Refer a Friend
              </Link>
              {state.showpop ? (
                <div className="popover">
                  <form onSubmit={handleSubmit(onSubmitReference)}>
                    <div className="popover-content">
                      <div className="refer-friend-form">
                        <div className="field-group">
                          <label for="referemail">Email ID</label>
                          <input
                            type="text"
                            className="field-input"
                            placeholder="Enter your Friend's email"
                            id="referemail"
                            name="referemail"
                            ref={register({
                              required: "Email Required",
                              pattern: {
                                value: /^\S+@\S+$/i,
                                message: "Invalid Email",
                              },
                              minLength: { value: 10, message: "Too Short" },
                              maxLength: 80,
                            })}
                          />
                          {errors.referemail && (
                            <p className="field-error">
                              {errors.referemail.message}
                            </p>
                          )}
                        </div>
                        <div className="text-right">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : null}
            </div>
          </nav>
        ) : (
          <nav className="nav main-nav">
            {currLoc === "/" ? (
              <Link className="nav-link" to="/">
                Home
              </Link>
            ) : (
              <Link className="nav-link" to="/">
                Home
              </Link>
            )}
            
            {/* <a href="https://techtownequity.invportal.com/signup" className="nav-link">Investments</a> */}
            <a href="#" className="nav-link" onClick={openInvestments}>Investments</a>            
            <Modal
              isOpen={InvestmentIsOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeInvestments}
              // style={customStyles}
              contentLabel="Sign Up"
            >
              <button onClick={closeInvestments} className="btn modal-close-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                  <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                </svg>
              </button>
              <iframe title="signUp" width="100%" height="950px" src="https://techtownequity.invportal.com/signup" frameBorder="0"></iframe>
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            </Modal>
            <Modal
              isOpen={LoginIsOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeLogin}
              // style={customStyles}
              contentLabel="Login"
            >
              <button onClick={closeLogin} className="btn modal-close-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                  <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                </svg>
              </button>
              <iframe title="login" width="100%" height="950px" src="https://techtownequity.invportal.com/login" frameBorder="0"></iframe>
            </Modal>
            <Link className="nav-link" to="/resources">Resources</Link>
            <div className="dropdown">
              <Link className="nav-link">
                <span>Company</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  className="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </Link>
              <div className="dropdown-menu">
                <Link className="nav-link" to="/about">
                  About us
                </Link>
              </div>
            </div>
            <Link className="nav-link" to="/contact">
              Contact us
            </Link>
            {/* <Link className="nav-link" to="https://techtownequity.invportal.com/login">
              Login
            </Link> */}
            {/* <a href="https://techtownequity.invportal.com/login" className="nav-link">Login</a> */}
            <a href="#" className="nav-link" onClick={openLogin}>Login</a>
          </nav>
        )}

        {/* <Switch>
                        <Route exact path='/' components={Home}></Route>
                        <Route exact path='/marketplace' components={Marketplace}></Route>                        
                        <Route exact path='/login' components={Login}></Route>
                        <Route exact path='/logout' components={Logout}></Route>
                        <Route exact path='/contact' components={Contact}></Route>
                        <Route exact path='/about' components={About}></Route>
                        <Route exact path='/privacy-policy' components={PrivacyPolicy}></Route>
                        <Route exact path='/terms' components={Terms}></Route>
                        <Route exact path='/resources' component={Resources}></Route>
                        <Route exact path='/signup' components={Signup}></Route>
                        <Route exact path="/dealpdfviewer" components={DealPDFViewer}></Route>
                    </Switch> */}
      </div>
    </header>
  );
};

export default Header;
