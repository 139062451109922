import React, {Component} from 'react';
import Deals from './Deals'

export default class Marketplace extends Component {

    state = {
        isLoggedIn: false,
        authName: ''
    }

    constructor(props){
        super(props);       
    }

    render(){

        if(localStorage.getItem('authName') != ''){
            this.state = {
                isLoggedIn: true,
                authName: localStorage.getItem('authName')
            }
        }

        console.log(this.state);

        const { isLoggedIn, authName } = this.state;
        
        if(isLoggedIn){
            return (
                <div>                      
                    {/* <h2>Status: {this.props.loggedInStatus}</h2>              */}
                    <main>
                        <Deals />
                    </main>
                </div>
            );
        }else{
            return (
                <div>
                    
                </div>
            );
        }

        
    }
}