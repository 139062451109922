import React, { Component, useEffect } from "react";
// import Recaptcha from 'react-recaptcha';
import { 
  BrowserRouter as Router, 
  Route, 
  Link, 
  Switch 
} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Home from './components/Home';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Logout from './components/Logout';
import Signup from './components/Signup';
import Contact from './components/Contact';
import About from './components/About';
import Layout from './components/Layout';
import Marketplace from './components/Marketplace';
import DealDetails from './components/DealDetails';
import PrivacyPolicy from './components/PrivacyPolicy';
import Resources from './components/Resources';
import Terms from './components/Terms';
import DealPDFViewer from './components/DealPDFViewer';
import axios from 'axios';

import './normalize.css';
import './main.css';
import './App.css';

const browserHistory = createBrowserHistory();
localStorage.removeItem("mediaPath");
localStorage.setItem("basePath","https://techtownequity.com/admin/wp-json");
// localStorage.setItem("basePath","http://localhost/TechTown/wp-json");
// localStorage.setItem("mediaPath","http://localhost:3000/public/images");
localStorage.setItem("mediaPath","https://techtownequity.com/images");

const basePath = localStorage.getItem("basePath");

export default class App extends Component {

  state = {};

  componentDidMount = () => {       

    if(localStorage.getItem("authToken") != null){

      var config = {
          method: 'post',
          url: `${basePath}?rest_route=/jwt-auth/v1/token/validate`,
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer '+localStorage.getItem("authToken"),
          },
      };
    
      axios(config)
      .then(function (response) {
        console.log('token_validate', response);            
      })
      .catch(function (error) {
          console.log('wp/v2/reference: testApiCode->catch', error);
      });
        
      axios.get(`${basePath}?rest_route=/wp/v2/users/me`,{
          headers: {
            'Content-Type': 'application/json',
            "Authorization" : 'Bearer ' + localStorage.getItem("authToken")
          }
      })
      .then(function (response){
          localStorage.setItem("authUID",response.data.id);
      })
      .catch(err => console.log(err));

      this.setState({
          token: localStorage.getItem("authToken"),
          uid: localStorage.getItem("authUID"),
          name: localStorage.getItem("authName")
      })
    }    
  }

  constructor(props){
    super(props);   
  }

  render () {
    return (
      <div className="App">
        <Router history={browserHistory} >
          <Layout user={this.state.name} >
            
            {/* <Route 
              exact 
              path={"/"}
              render={props => (
                <Home {...props} handleLogin={this.handleLogin} loggedInStatus={this.state.loggedInStatus} />
              )}
            />
            <Route 
              exact 
              path='/marketplace' 
                render={props => (
                  <Marketplace {...props} loggedInStatus={this.state.loggedInStatus} />
                )}
            />
            <Route 
              exact 
              path='/login' 
                render={props => (
                  <Login {...props} loggedInStatus={this.state.loggedInStatus} />
                )}
            />
            <Route 
              exact 
              path='/signup' 
                render={props => (
                  <Signup {...props} loggedInStatus={this.state.loggedInStatus} />
                )}
            /> */}

            <Route exact path='/'  component={() => <Home user={this.state.name} />}></Route> 
            <Route exact path='/marketplace' component={Marketplace}></Route> 
            <Route exact path='/dealdetails/:id' component={DealDetails}></Route>
            <Route exact path='/login' component={Login}></Route> 
            <Route exact path='/forgot-password' component={ForgotPassword}></Route> 
            <Route exact path='/logout' component={Logout}></Route> 
            <Route exact path='/signup' component={Signup}></Route> 
            <Route exact path='/contact' component={Contact}></Route>
            <Route exact path='/about' component={About}></Route>
            <Route exact path='/resources' component={Resources}></Route>
            <Route exact path='/privacy-policy' component={PrivacyPolicy}></Route>
            <Route exact path='/terms' component={Terms}></Route>
            <Route exact path="/dealpdfviewer" components={DealPDFViewer}></Route>
          
          </Layout>
        </Router>
      </div>
    );
  }
}