import React, { Component } from 'react';
import ResourceItem from './ResourceItem';
import axios from 'axios';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch
} from 'react-router-dom';

const basePath = localStorage.getItem("basePath");

export default class Resources extends Component {

    state = {
        resourcecontent: [],
        isLoaded: false
    }

    componentDidMount() {
        axios.get(`${basePath}?rest_route=/wp/v2/resources`)
            // .then(res => console.log(res))
            .then(res => this.setState({
                resourcecontent: res.data,
                isLoaded: true
            }))
            .catch(err => console.log(err));
    }

    render() {
        const { resourcecontent, isLoaded } = this.state;
        const { content } = resourcecontent;
        const { title } = resourcecontent;
        console.log('CCC', resourcecontent)

        const renderAll = resourcecontent.map(resource => <ResourceItem key={resource.id} resource={resource} />)

        if (isLoaded) {

            return (
                <main>
                    <section className="page-banner" style={{ backgroundColor: '#fff' }}>
                        <div className="page-banner-bg">
                            <img src="images/about-us.jpg" alt="" />
                        </div>
                        <div className="container text-center">
                            <div className="page-title">
                                <h1>Resources</h1>
                            </div>
                        </div>
                    </section>
                    <section className="page-content">
                        <div className="container">
                            {renderAll}
                        </div>
                    </section>
                </main>
            );
        }
        return (
            <div>

            </div>
        )
    }
}
