/*
 * A simple React component
 */

import React, {Component} from 'react';
import axios from 'axios';

export default class StarRating extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stars: [],
            rating: 0,
            hovered: 0,
            selectedIcon: "★",
            deselectedIcon: "☆"
        };

        let outOf = props.outOf ? props.outOf : 5;

        for (var i = 0; i < outOf; i++) {
            this.state.stars.push(i + 1);
        }
    }

    changeRating(newRating) {
        
        console.log(newRating);

        document.querySelector('#starrating').value = newRating;

        this.setState({
            rating: newRating
        });

        if (this.props.onChange)
            this.props.onChange(newRating);
    }

    hoverRating(rating) {
        this.setState({
            hovered: rating
        });
    }

    render() {

        const { stars, rating, hovered, deselectedIcon, selectedIcon } = this.state;

        return (
            
                <span className="rating" style={{ fontSize: '1.6em', color: "#1454D6" }}>
                    
                    {stars.map(star => {
                        return (
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => { this.changeRating(star); }}
                                onMouseEnter={() => { this.hoverRating(star); }}
                                onMouseLeave={() => { this.hoverRating(0); }}
                            >
                                {rating < star ?
                                    hovered < star ? deselectedIcon : selectedIcon
                                    :
                                    selectedIcon
                                }
                            </span>
                        );
                    })}

                </span>
            
        );
    }
}