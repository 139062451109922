import React, { Component } from 'react';
import axios from 'axios';

const basePath = localStorage.getItem("basePath");
export default class Terms extends Component {

    state = {
        termsContent: [],
        isLoaded: false
    }

    componentDidMount() {
        axios.get(`${basePath}?rest_route=/wp/v2/pages/772&_embedded`)
            // .then(res => console.log(res))
            .then(res => this.setState({
                termsContent: res.data,
                isLoaded: true
            }))
            .catch(err => console.log(err));
    }

    render() {
        const { termsContent, isLoaded } = this.state;
        const { content } = termsContent;
        const { title } = termsContent;
        console.log(termsContent)

        if (isLoaded) {

            return (
                <main>
                    <section className="page-banner" style={{ backgroundColor: '#fff' }}>
                        <div className="page-banner-bg">
                            <img src="images/privacy-policy.jpg" alt="" />
                        </div>
                        <div className="container text-center">
                            <div className="page-title">
                                <h1>{title.rendered}</h1>
                            </div>
                        </div>
                    </section>
                    <section className="page-content">
                        <div className="container">
                        <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
                        </div>
                    </section>
                </main>
            );
        }
        return (
            <div>

            </div>
        )
    }
}
