import { 
    BrowserRouter as Router, 
    Route, 
    Link, 
    Switch, 
    useHistory
} from 'react-router-dom'; 
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const ForgotPassword = props => {
    
    const { register, handleSubmit,  errors } = useForm();
    const history = useHistory();
    const basePath = localStorage.getItem("basePath");

    const onSubmit = (data, e) => {        
        const email = data.email;
        axios.get(`${basePath}?rest_route=/wp/v2/users/&search=${email}`,{})
        .then(function (response){
            console.log("response.data[0].acf.is_approved", response);
            if (response.data) {
                console.log("hit1");
                axios.post(`${basePath}?rest_route=/wcra/v1/forgotpass`, {
                    user_login: data.email,
                  },
                    { withCredentials: true }
                  )
                  .then(response => {
                    console.log("response", response); 
                    e.target.reset();
                    let successMessage = document.querySelector('.successMsg');
                    successMessage.innerHTML = "<h2>Password reset request received. You will receive an email in few seconds.</h2>";
                    setTimeout(() => {
                        successMessage.innerHTML = "";
                      }, 5000);
                    //   localStorage.setItem('myData', successMessage);
                })
                .catch(error => {
                        console.log("reset error", error);
                        let errorMessage = document.querySelector('.errorMsg');
                        errorMessage.innerHTML = "Email address not found"; 
                        setTimeout(() => {
                            errorMessage.innerHTML = "";
                          }, 5000);
                        // errorMessage.innerHTML = error; 
                      });
              }            
        })
        .catch(err => console.log(err));
    }

    return (
        <main className="main-wrapper">
            <div className="card auth-card">
                <div className="card-content">
                    <div className="successMsg" style={{color:'green'}}>
                        <label></label>
                    </div>
                    <div className="errorMsg" style={{color:'red'}}>
                        <label></label>
                    </div>
                    {/* <img src="images/techtown-logo.svg" alt="" width="130" /> */}
                    <h1 className="fw-bold">Forgot Password</h1>
                    <p>Please enter your registered email address. </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="field-group">                                    
                            <input type="text" className="field-input" name="email" id="email" placeholder="Email" ref={register({required: "Email Required",pattern: {value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/, message: "Invalid Email"}, minLength: {value:10,message:"Too Short"}, maxLength: 80})}  />
                            {errors.email && <p className="field-error">{errors.email.message}</p>}
                        </div>
                        <div className="d-flex jc-between ai-center">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="auth-footer">
                <div className="text-center">
                    <Link to="/Login">Back to Login</Link>
                </div>
            </div>
        </main>        
    );
}

export default ForgotPassword;