import Header from './Header'
import Footer from './Footer'

const Layout = (props) => {
    const name = props.user;
    return(        
        <div>
            <Header user={props.user} />
                {props.children}
            <Footer />
        </div>
    );
}

export default Layout;