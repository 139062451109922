import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

const ResourceItem = (props) => {

    const { id, title, acf } = props.resource;

    return (
        <a target="_blank" href={acf.link} className="deal-item" rel="noopener noreferrer" >
            <div className="deal-thumbnail">
                <div className="thumbnail-wrapper">
                    {acf.image == false ?
                        <></>
                        :
                        <img src={acf.image.sizes.medium} alt="" />
                    }
                    {acf.video != '' ?
                        <div dangerouslySetInnerHTML={{ __html: acf.video }} />
                        :
                        <></>
                    }
                </div>
            </div>
            <div className="deal-summary">
                <h2 className="deal-title">
                    <strong>{title.rendered}</strong>
                </h2>
                <p>{acf.description}</p>
            </div>
        </a>
    )
}

export default ResourceItem;
