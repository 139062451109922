const AboutUS = () => {
    return (
        <main>
            <section className="page-banner" style={{ backgroundColor: '#ffffff' }}>
                <div className="page-banner-bg">
                    <img src="images/about-us.jpg" alt="" />
                </div>
                <div className="container text-center">
                    <div className="page-title ">
                        <h1>About us</h1>
                    </div>
                </div>
            </section>
            <section className="page-content">
                <div className="container" style={{ justifyContent: 'center', maxWidth: '960px', textAlign: 'justify' }}>
                    <h2>TEAM</h2>
                    <div className="d-flex" style={{ margin: '0 -24px' }}>
                        <p style={{ padding: '0 24px', flex: '1' }}>
                            <strong>Senthil Rangaswamy</strong> is the Founder and Principal at TechTown Equity. Senthil is an engineer, investor and entrepreneur with nearly two decades of experience working in the hi-tech semiconductor industry.  While creative problem solving is his strongest suit, real estate has been a life-long passion. Senthil has been actively involved in Texas real estate since 2010 and has direct experience involving acquisition, management and disposition of properties.  An outdoor enthusiast and resident of the beautiful Texas hill country, Senthil loves to go cycling. He also volunteers in local non-profits and devotes focused time to the Boy Scouts of America (BSA). Senthil has a Master’s degree in Electrical Engineering.
                        </p>
                        <p style={{ padding: '0 24px', flex: '1' }}>
                            <strong>Ashwin Balakrishnan</strong> is a managing partner at TechTown Equity. Ashwin is a chip designer, entrepreneur and avid investor with extensive experience in real estate syndications. He has over a decade of experience in the semiconductor industry and has worked for NXP, Intel and Marvell Semiconductors. His experience in real-estate investing includes market research, professional networking, identifying opportunities, raising capital, evaluating and vetting deals. Ashwin is passionate about technology and is constantly looking for ways to combine technology with his other interests. Ashwin loves fitness and likes to work out or play pickup soccer in his free time.
                        </p>
                    </div>
                    <h2>OUR STORY</h2>
                    <p>
                        After the great recession in 2010, like any other investor looking to diversify their investment portfolio, we started out in the residential real estate space with the goal of building a portfolio of cash flowing properties. In the following years, our knowledge of RE investing expanded rapidly as did our network of mentors, professionals and fellow investors in the vibrant Texas real estate scene.
                    </p>
                    <p>
                        Around 2016, as we were exploring other avenues in real estate investing that offer sustainable growth, we hit upon the world of Commercial properties. It didn’t take long for us to understand the uniqueness of this segment and the opportunities that it offers for long-term wealth building. We did our first commercial land acquisition in 2018 with a group of friends and haven't looked back since.
                    </p>
                    <p>
                        Over the last 10 years, our experience in real estate has equipped us with the know-hows of identifying good opportunities, vetting syndicated deals from a risk, feasibility and financial standpoint, as well as executing commercial real estate deals with high IRR. It is our goal and commitment to leverage this knowledge to assist and serve fellow savvy investors with similarly aligned interests, by providing a platform to invest in high quality commercial real estate assets.
                    </p>
                    <h2>INVESTMENT STRATEGY</h2>
                    <p>
                        At TechTown Equity, we have developed a refined investment strategy over the years that focuses on risk deleveraged returns. One of our core principles is to safeguard investor capital as topmost priority and provide a reliable path to capital return within a predictable amount of time in any of the investments that we get into. We strongly believe in doing extensive market research up-front, using a data-driven approach and focus on our financial models as much as we focus on location, demographics, growth and other key aspects of real-estate investing.
                    </p>
                </div>
            </section>
        </main>
    );
}

export default AboutUS;