import { 
    BrowserRouter as Router, 
    Route, 
    Link, 
    Switch,
    Redirect,
    useHistory
} from 'react-router-dom'; 
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const Logout = (props) => {
    
    let history = useHistory();

    localStorage.removeItem("authToken");
    localStorage.removeItem("authName");
    localStorage.removeItem("authUID");
    
    // this.state = {
    //     isLoggedIn: false,
    //     authName: ''
    // }
    //history.push("/");
    window.location.href = '/';

    return (
        <div>             
        </div>
    );
}

export default Logout;